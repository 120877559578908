require("jquery");
require("./bootstrap");
require("sweetalert");
require("slick-carousel");
require("smooth-scroll");
require("./cookie.js");
//require("aos");

$(".references__slider").slick({
    dots: true,
    infinite: true,
    speed: 1000,
    autoplaySpeed: 5000,
    fade: true,
    cssEase: "linear",
    arrows: false,
    autoplay: true,
    pauseOnHover: true,
    pauseOnFocus: true,
    adaptiveHeight: true,
});

//var scroll = new SmoothScroll('a[href*="#"]');
