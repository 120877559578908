require("vanilla-cookieconsent");

window.addEventListener("load", function () {
    window.cookieconsent = initCookieConsent();

    window.cookieconsent.run({
        autorun: true,
        current_lang: "sk",
        autoclear_cookies: true,
        page_scripts: true,
        force_consent: true,

        gui_options: {
            consent_modal: {
                layout: "box", // box,cloud,bar
                position: "bottom right", // bottom,middle,top + left,right,center
                transition: "slide", // zoom,slide
            },
            settings_modal: {
                layout: "bar", // box,bar
                // position: 'left',                // right,left (available only if bar layout selected)
                transition: "slide", // zoom,slide
            },
        },

        onFirstAction: function (user_preferences, cookie) {
            // callback triggered only once
        },

        onAccept: function (cookie) {
            // ... cookieconsent accepted
        },

        onChange: function (cookie, changed_preferences) {
            // ... cookieconsent preferences were changed
        },

        languages: {
            sk: {
                consent_modal: {
                    title: "<span>Dajte si k masáži cookies 🍪</span><button id='s-c-bn' class='c-bn cookie-close' onclick='cookieconsent.hide()' aria-label='Close'></button>",
                    description:
                        'Nie sú síce také mňam ako tie pečené, ale vieme vďaka nim, čo sa vám páči a aká masáž by bola pre vás tá najlepšia. Vy rozhodujete o tom, či a v akom rozsahu nám používanie cookies dovolíte. <br> <a aria-label="Cookie policy" class="cc-link" data-cc="c-settings" href="/pdf/cookies.pdf">Viac informácií</a>',
                    primary_btn: {
                        text: "Prijať všetky",
                        role: "accept_all", // 'accept_selected' or 'accept_all'
                    },
                    secondary_btn: {
                        text: "Nastavenia",
                        role: "settings", // 'settings' or 'accept_necessary'
                    },
                },
                settings_modal: {
                    title: "Nastavenia Cookies",
                    save_settings_btn: "Uložiť nastavenia",
                    accept_all_btn: "Prijať všetky",
                    reject_all_btn: "Zamietnúť všetky", // optional, [v.2.5.0 +]
                    cookie_table_headers: [
                        {
                            col1: "Názov",
                        },
                        {
                            col2: "Typ",
                        },
                    ],
                    blocks: [
                        {
                            description:
                                "Naše webové stránky používajú Cookies. Slúžia napríklad na účel analýzy návštevnosti tejto stránky alebo ďalšie vylepšovanie webu prostredníctvom anonymných štatistík a na zobrazovanie relevatného obsahu a reklamy.",
                        },
                        {
                            title: "Nevyhnutné",
                            description:
                                "Tieto Cookies sú potrebné pre správne fungovanie webovej stránky, a kvôli tomu ich nie je možné vypnúť.",
                            toggle: {
                                value: "necessary",
                                enabled: true,
                                readonly: true,
                            },
                        },
                        {
                            title: "Analytické",
                            description:
                                "Tieto Cookies nám pomáhajú pochopiť ako používate naše webové stránky a vďaka ním ich vieme následne zlepšovať.",
                            toggle: {
                                value: "analytics",
                                enabled: false,
                                readonly: false,
                            },
                            cookie_table: [
                                {
                                    col1: "Google Tag Manager",
                                    col2: "Tretia strana",
                                },
                            ],
                        },
                        // {
                        //     title: "Marketingové",
                        //     description:
                        //         "Použitím týchto Cookies Vám môžeme zobrazovať relevantný obsah a reklamy, ktoré môžu byť pre Vás zaujimavé a užitočné.",
                        //     toggle: {
                        //         value: "ads",
                        //         enabled: false,
                        //         readonly: false,
                        //     },
                        //     cookie_table: [
                        //         {
                        //             col1: "Google Ads",
                        //             col2: "Tretia strana",
                        //         },
                        //         /* {
                        //         col1: 'Facebook Ads',
                        //         col2: 'Tretia strana',
                        //     },
                        //     {
                        //         col1: 'Sklik',
                        //         col2: 'Tretia strana',
                        //     }, */
                        //         {
                        //             col1: "LeadHub",
                        //             col2: "Tretia strana",
                        //         },
                        //     ],
                        // },
                        {
                            title: "Viac informácií",
                            description:
                                'Všetky informácie ohľadom cookies a spracúvania osobných údajov nájdete na <a class="cc-link" href="/docs/gdpr.pdf">GDPR</a>.',
                        },
                    ],
                },
            },
        },
    });
});
